import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/accounts/[scopeId]/transactions": [4],
		"/accounts/[scopeId]/transactions/multi": [6],
		"/accounts/[scopeId]/transactions/new": [7],
		"/accounts/[scopeId]/transactions/[id]/edit": [5],
		"/contact": [8],
		"/dashboard": [9],
		"/entities": [10],
		"/entities/new": [55],
		"/entities/[scopeId]/accounts": [12],
		"/entities/[scopeId]/accounts/new": [14],
		"/entities/[scopeId]/accounts/[id]/edit": [13],
		"/entities/[scopeId]/archive-nodes": [15],
		"/entities/[scopeId]/archive-nodes/new": [18],
		"/entities/[scopeId]/archive-nodes/[id]/delete": [16],
		"/entities/[scopeId]/archive-nodes/[id]/edit": [17],
		"/entities/[scopeId]/bank-accounts": [19],
		"/entities/[scopeId]/bank-accounts/new": [21],
		"/entities/[scopeId]/bank-accounts/[id]/edit": [20],
		"/entities/[scopeId]/banks": [22],
		"/entities/[scopeId]/banks/new": [24],
		"/entities/[scopeId]/banks/[id]/edit": [23],
		"/entities/[scopeId]/blockchains": [25],
		"/entities/[scopeId]/blockchains/new": [27],
		"/entities/[scopeId]/blockchains/[id]/edit": [26],
		"/entities/[scopeId]/centralized-exchange-accounts": [28],
		"/entities/[scopeId]/centralized-exchange-accounts/new": [30],
		"/entities/[scopeId]/centralized-exchange-accounts/[id]/edit": [29],
		"/entities/[scopeId]/centralized-exchanges": [31],
		"/entities/[scopeId]/centralized-exchanges/new": [33],
		"/entities/[scopeId]/centralized-exchanges/[id]/edit": [32],
		"/entities/[scopeId]/counterparts": [34],
		"/entities/[scopeId]/counterparts/new": [37],
		"/entities/[scopeId]/counterparts/[id]/convert": [35],
		"/entities/[scopeId]/counterparts/[id]/edit": [36],
		"/entities/[scopeId]/currencies": [38],
		"/entities/[id]/edit": [11],
		"/entities/[scopeId]/entity-settings": [39],
		"/entities/[scopeId]/reports": [40],
		"/entities/[scopeId]/token-lists": [41],
		"/entities/[scopeId]/token-lists/new": [43],
		"/entities/[scopeId]/token-lists/[id]/edit": [42],
		"/entities/[scopeId]/tokens": [44],
		"/entities/[scopeId]/tokens/new": [46],
		"/entities/[scopeId]/tokens/[id]/edit": [45],
		"/entities/[scopeId]/transaction-uploads": [47],
		"/entities/[scopeId]/transaction-uploads/csv": [49],
		"/entities/[scopeId]/transaction-uploads/mappings": [50],
		"/entities/[scopeId]/transaction-uploads/new": [51],
		"/entities/[scopeId]/transaction-uploads/[id]/edit": [48],
		"/entities/[scopeId]/wallets": [52],
		"/entities/[scopeId]/wallets/new": [54],
		"/entities/[scopeId]/wallets/[id]/edit": [53],
		"/forgot-password": [56],
		"/good-bye": [57],
		"/profile": [58],
		"/reset-password": [59],
		"/sign-in": [60],
		"/sign-up": [61]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';