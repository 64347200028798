import { replayIntegration, browserTracingIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { sentryDSN } from '$lib';

Sentry.init({
    dsn: sentryDSN,
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    enableTracing: true,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    profilesSampleRate: 1.0,

    // TODO: Before we go to production: we need to set maskAllText to true
    integrations: [
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false,
            mutationBreadcrumbLimit: 1000,
            mutationLimit: 5000,
            networkRequestHeaders: []
        }),
        browserTracingIntegration(),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Integrations.Breadcrumbs()
    ]
});
